<template>
  <div>
    <div class="main-title">邮件记录</div>
    <div class="content-list-page page-content">
      <div class="margin-b-sm margin-l-sm">
        <span class="fl-l margin-l-sm">
          <el-input v-model="searchData.subject" placeholder="主题" />
        </span>

        <span class="margin-l-sm fl-l">
          <el-button
            icon="el-icon-search"
            size="small"
            type="primary"
            @click="search"
            >搜索</el-button
          >
        </span>
        <div class="table-content margin-t-sm">
          <el-table
            ref="singleTable"
            v-loading="loading"
            :data="tableData"
            :header-cell-style="{ padding: 0 }"
            style="width: 100%; background: #fff"
          >
            <el-table-column
              align="center"
              label="ID"
              property="id"
              width="80"
            />
            <el-table-column align="center" label="收件人" property="receivers">
              <template slot-scope="scope">
                <div v-for="item in scope.row.receivers" :key="item">
                  {{ item }}
                </div>
              </template>
            </el-table-column>
            <el-table-column align="center" label="主题" property="subject" />
            <el-table-column align="center" label="发送者">
              <template slot-scope="scope">
                <span>{{
                  scope.row.user_id !== 1 ? scope.row.user_name : "系统"
                }}</span>
              </template>
            </el-table-column>
            <el-table-column align="center" label="备注" property="remark" />
            <el-table-column align="center" label="状态" property="subject">
              <template slot-scope="scope">
                {{ getStatus(scope.row.status) }}
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              label="创建时间"
              property="created_at"
            />
            <el-table-column
              align="center"
              label="更新时间"
              property="updated_at"
            />

            <el-table-column label="操作">
              <template slot-scope="scope">
                <span class="margin-r-sm">
                  <el-link
                    slot="reference"
                    type="primary"
                    @click="particulars(scope.row.id)"
                    >详情
                  </el-link>
                </span>
              </template>
            </el-table-column>
          </el-table>
        </div>

        <div class="table-batch margin-l-sm">
          <span class="fl-l">
            <el-pagination
              :current-page="page"
              :page-size="pageSize"
              :page-sizes="[10, 20]"
              :total="total"
              background
              layout="total, sizes, prev, pager, next, jumper"
              small
              @size-change="setPageSize"
              @current-change="setPage"
            >
            </el-pagination>
          </span>
          <div style="clear: both"></div>
        </div>
      </div>
    </div>

    <el-dialog
      :visible.sync="dialogVisible"
      append-to-body
      title="详情"
      width="70%"
    >
      <div v-loading="dialog" class="padding-b-sm">
        <el-descriptions :column="1" border class="margin-top">
          <el-descriptions-item>
            <template slot="label"> 主题</template>
            {{ particularsData.subject }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label"> 内容</template>
            <div v-html="particularsData.content"></div>
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label"> 附件</template>
            <div class="table">
              <el-table
                :data="particularsData.email_attachment_files"
                style="width: 100%"
              >
                <el-table-column label="名称" prop="name"></el-table-column>
                <el-table-column align="center" label="大小" property="size">
                  <template slot-scope="scope">
                    <span>{{ unitConversion(scope.row.size) }}</span>
                  </template>
                </el-table-column>
                <el-table-column label="类型" prop="ext"></el-table-column>
              </el-table>
            </div>
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label"> 创建时间</template>
            {{ particularsData.created_at }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label"> 更新时间</template>
            {{ particularsData.updated_at }}
          </el-descriptions-item>
        </el-descriptions>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { config } from "@/js/config";

export default {
  data() {
    return {
      //图片前缀
      path: config.imageUrl,
      dialogVisible: false,
      searchData: {},
      loading: false,
      //分页
      page: 1,
      pageSize: 10,
      total: 0,
      tableData: [],
      dialog: false,
      particularsData: {},
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    ...mapActions("mailbox", ["sendRecordList", "sendRecordDetail"]),
    unitConversion(size) {
      return (size / 1024 / 1024).toFixed(2) + "M";
    },

    async particulars(id) {
      try {
        this.dialog = true;
        this.dialogVisible = true;
        const { data } = await this.sendRecordDetail({ id });
        this.particularsData = data;
      } finally {
        this.dialog = false;
      }
    },
    getStatus(row) {
      switch (row) {
        case 1:
          return "发送中";
        case 2:
          return "发送成功";
        case 3:
          return "发送失败";
      }
    },
    editAimActivity(id) {
      this.$router.push("/accessory/edit/" + id);
    },
    search() {
      this.page = 1;
      this.getList();
    },
    //分页
    setPageSize(pageSize) {
      this.pageSize = pageSize;
      this.getList();
    },
    setPage(page) {
      this.page = page;
      this.getList();
    },
    async getList() {
      this.loading = true;
      let from = {
        ...this.searchData,
        page: this.page,
        pageSize: this.pageSize,
      };
      const { data } = await this.sendRecordList(from);
      this.tableData = data.list;
      this.total = data.total;
      this.loading = false;
    },
  },
};
</script>
<style scoped>
.table {
  max-height: 40vh;
  overflow: auto;
}

.table::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.table::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
}

/* 滚动条滑块 */
.table::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background: #bbb;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.25);
}
</style>